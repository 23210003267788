import { TodosState } from './core/states/todos.state';
import { ApplicationConfig, EnvironmentProviders, ErrorHandler, importProvidersFrom, LOCALE_ID, provideExperimentalZonelessChangeDetection, Provider } from '@angular/core';

import { NgxsModule, NoopNgxsExecutionStrategy } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AuthState } from './core/states/auth.state';
import { CoreModule } from './core/core.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { OrganisationsState } from './core/states/organisations.state';
import { UsersState } from './core/states/users.state';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { UploadsState } from './core/states/uploads.state';
import { IMAGE_CONFIG, IMAGE_LOADER, ImageLoaderConfig, provideImgixLoader, registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl'
import { SusbcriptionTypesState } from './core/states/subscription-types.state';
import { RequestCountInterceptor } from './core/interceptors/request-count.interceptor';
import { GenericErrorHandler } from './core/error-handlers/generic-error.handler';
import { AttachmentState } from './core/states/attachments.state';
import { SelectionState } from './core/states/selection.state';
import { KlicMetadataState } from './core/states/klic-metadata';
import { AppState } from './core/states/app.state';
import { ProjectsState } from './core/states/project.state';
import { ShopState } from './core/states/shop.state';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MapState } from './core/states/map.state';
import { StateNames } from './core/states/models/state-names.enum';
import { provideRouter, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getDutchPaginatorIntl } from './shared/paginator-localisation/dutch-mat-paginator';

registerLocaleData(localeNl, 'nl');

const formFieldDefaults: MatFormFieldDefaultOptions = {
    appearance: 'fill',
    floatLabel: 'always'
};

const providers: (EnvironmentProviders | Provider)[] = [
    provideRouter(routes, withRouterConfig({ paramsInheritanceStrategy: 'always' }), withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    importProvidersFrom(NgxsModule.forRoot([
        AuthState,
        OrganisationsState,
        UsersState,
        UploadsState,
        SusbcriptionTypesState,
        AttachmentState,
        SelectionState,
        KlicMetadataState,
        AppState,
        ProjectsState,
        TodosState,
        ShopState,
        ProjectsState,
        MapState
    ], {
        selectorOptions: {
            injectContainerState: false,
            suppressErrors: false,
        },
        developmentMode: !environment.production,
        executionStrategy: NoopNgxsExecutionStrategy
    })),
    importProvidersFrom(NgxsStoragePluginModule.forRoot({
        keys: [
            StateNames.shop,
            StateNames.auth,
            StateNames.app_v4,
        ],
        migrations: []
    })),
    importProvidersFrom(NgxsReduxDevtoolsPluginModule.forRoot({
        disabled: environment.production
    })),
    importProvidersFrom(ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
    })),
    importProvidersFrom(CoreModule),
    provideImgixLoader('https://geogap.imgix.net/'),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestCountInterceptor, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: formFieldDefaults },
    { provide: LOCALE_ID, useValue: "nl-NL" },
    { provide: ErrorHandler, useClass: GenericErrorHandler },
    provideNativeDateAdapter(),
    provideExperimentalZonelessChangeDetection(),
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() }
];


if (environment.useImgix === true) {
    providers.push(
        provideImgixLoader(environment.imgixDomain),
        {
            provide: IMAGE_CONFIG,
            useValue: {
                placeholderResolution: 30
            }
        },
    );
} else {
    providers.push(
        {
            provide: IMAGE_LOADER,
            useValue: (cfg: ImageLoaderConfig) => {
                return cfg.src;
            }

        },
    );
}

export const appConfig: ApplicationConfig = {
    providers: providers
};
